import React, { Component } from 'react';

export default class ScrollUp extends Component {
  render() {
    return (

      <div id="back-top">
        <a title="Go to Top" href="#"> <i className="fas fa-level-up-alt" /></a>
      </div>
    );
  }
};