import React, { Component } from 'react';

export default class BeersPage extends Component {
  render() {
    return (
      <main>
        <section className="section-padding40">
          <h2>Coming soon!</h2>
        </section>
      </main>
    );
  }
};