import React, { Component } from 'react';

export default class PageNotFound extends Component {
  render() {
    return (
      <main>
        <h2>Sorry, that page doesn't exist</h2>
      </main>
    );
  }
};